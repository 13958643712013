import PersonAddOutlined from '@mui/icons-material/PersonAddOutlined';
import ButtonWithIcon from './ButtonWithIcon';
import React from 'react';
import { useHistory } from 'react-router';
import ApplicationPaths from '../../utils/paths';

const SignupContributorButton = (): JSX.Element => {
  const history = useHistory();
  function onClick() {
    history.push(ApplicationPaths.CONTRIBUTOR_SIGNUP);
  }
  return (
    <React.Fragment>
      <ButtonWithIcon
        onClick={onClick}
        text={'Signup New Subscriber'}
        icon={<PersonAddOutlined style={{ fontSize: '30px' }} />}
      />
    </React.Fragment>
  );
};

export default SignupContributorButton;
