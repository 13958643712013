import React from "react";
import { Box, Container, Grid, Typography} from '@mui/material';

type Props = {
    text: string;
    subText: string;
    icon?: React.ReactNode;
    link?: React.ReactNode;
}

const DashboardHeading = (props: Props): JSX.Element => {
    return(
        <Grid container spacing={3} style={{ marginBottom: '25px' }} justifyContent="center">
        <Box sx={{alignItems: 'center', textAlign: "center", margin: "auto"}}> 
        <Typography sx={{ color: "#fff", fontSize: "48px", fontWeight: 'bold'}}>
            {props.text}
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "14px", display: 'flex', alignItems: 'center', minHeight:'30px'}} >
            <Box component="span" sx={{marginRight:'5px'}}>
                {props.icon} 
            </Box>
            <Box component="span" >
                {props.subText}
            </Box>
            
        </Typography>
        <Box sx={{marginLeft:'5px', '& a': {color: '#fff'}}}>
                {props.link}
        </Box>
        </Box>
        </Grid>
    )
}

export default DashboardHeading;