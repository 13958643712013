type ValidationErrors = {
    [key:string]: string[]
}

type FormattedError = {
    [key:string]: string
}

export const formatFormErrors = (validationErrors: ValidationErrors): FormattedError => {
    const formattedErrors:FormattedError = {}

    Object.keys(validationErrors).map((key)=>{
        formattedErrors[key] = validationErrors[key].join(', ');
      })

    return formattedErrors;
}


export const isValidPostalCode = (postalCode: string, country:string) => {
    let postalCodeRegex:RegExp;
  
    switch (country) {
      case "United States of America":
        postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
        break;
      case "Canada":
        postalCodeRegex = /^([A-Za-z][0-9][A-Za-z])\s*([0-9][A-Za-z][0-9])$/;
        break;
      default:
        return true;
    }
    return postalCodeRegex.test(postalCode);
  }


export const countryList = [
    {name:"Afganistan", id:"Afghanistan"},
    {name:"Albania", id:"Albania"},
    {name:"Algeria", id:"Algeria"},
    {name:"American Samoa", id:"American Samoa"},
    {name:"Andorra", id:"Andorra"},
    {name:"Angola", id:"Angola"},
    {name:"Anguilla", id:"Anguilla"},
    {name:"Antigua & Barbuda", id:"Antigua & Barbuda"},
    {name:"Argentina", id:"Argentina"},
    {name:"Armenia", id:"Armenia"},
    {name:"Aruba", id:"Aruba"},
    {name:"Australia", id:"Australia"},
    {name:"Austria", id:"Austria"},
    {name:"Azerbaijan", id:"Azerbaijan"},
    {name:"Bahamas", id:"Bahamas"},
    {name:"Bahrain", id:"Bahrain"},
    {name:"Bangladesh", id:"Bangladesh"},
    {name:"Barbados", id:"Barbados"},
    {name:"Belarus", id:"Belarus"},
    {name:"Belgium", id:"Belgium"},
    {name:"Belize", id:"Belize"},
    {name:"Benin", id:"Benin"},
    {name:"Bermuda", id:"Bermuda"},
    {name:"Bhutan", id:"Bhutan"},
    {name:"Bolivia", id:"Bolivia"},
    {name:"Bonaire", id:"Bonaire"},
    {name:"Bosnia & Herzegovina", id:"Bosnia & Herzegovina"},
    {name:"Botswana", id:"Botswana"},
    {name:"Brazil", id:"Brazil"},
    {name:"British Indian Ocean Ter", id:"British Indian Ocean Ter"},
    {name:"Brunei", id:"Brunei"},
    {name:"Bulgaria", id:"Bulgaria"},
    {name:"Burkina Faso", id:"Burkina Faso"},
    {name:"Burundi", id:"Burundi"},
    {name:"Cambodia", id:"Cambodia"},
    {name:"Cameroon", id:"Cameroon"},
    {name:"Canada", id:"Canada"},
    {name:"Canary Islands", id:"Canary Islands"},
    {name:"Cape Verde", id:"Cape Verde"},
    {name:"Cayman Islands", id:"Cayman Islands"},
    {name:"Central African Republic", id:"Central African Republic"},
    {name:"Chad", id:"Chad"},
    {name:"Channel Islands", id:"Channel Islands"},
    {name:"Chile", id:"Chile"},
    {name:"China", id:"China"},
    {name:"Christmas Island", id:"Christmas Island"},
    {name:"Cocos Island", id:"Cocos Island"},
    {name:"Colombia", id:"Colombia"},
    {name:"Comoros", id:"Comoros"},
    {name:"Congo", id:"Congo"},
    {name:"Cook Islands", id:"Cook Islands"},
    {name:"Costa Rica", id:"Costa Rica"},
    {name:"Cote DIvoire", id:"Cote DIvoire"},
    {name:"Croatia", id:"Croatia"},
    {name:"Cuba", id:"Cuba"},
    {name:"Curaco", id:"Curacao"},
    {name:"Cyprus", id:"Cyprus"},
    {name:"Czech Republic", id:"Czech Republic"},
    {name:"Denmark", id:"Denmark"},
    {name:"Djibouti", id:"Djibouti"},
    {name:"Dominica", id:"Dominica"},
    {name:"Dominican Republic", id:"Dominican Republic"},
    {name:"East Timor", id:"East Timor"},
    {name:"Ecuador", id:"Ecuador"},
    {name:"Egypt", id:"Egypt"},
    {name:"El Salvador", id:"El Salvador"},
    {name:"Equatorial Guinea", id:"Equatorial Guinea"},
    {name:"Eritrea", id:"Eritrea"},
    {name:"Estonia", id:"Estonia"},
    {name:"Ethiopia", id:"Ethiopia"},
    {name:"Falkland Islands", id:"Falkland Islands"},
    {name:"Faroe Islands", id:"Faroe Islands"},
    {name:"Fiji", id:"Fiji"},
    {name:"Finland", id:"Finland"},
    {name:"France", id:"France"},
    {name:"French Guiana", id:"French Guiana"},
    {name:"French Polynesia", id:"French Polynesia"},
    {name:"French Southern Ter", id:"French Southern Ter"},
    {name:"Gabon", id:"Gabon"},
    {name:"Gambia", id:"Gambia"},
    {name:"Georgia", id:"Georgia"},
    {name:"Germany", id:"Germany"},
    {name:"Ghana", id:"Ghana"},
    {name:"Gibraltar", id:"Gibraltar"},
    {name:"Great Britain", id:"Great Britain"},
    {name:"Greece", id:"Greece"},
    {name:"Greenland", id:"Greenland"},
    {name:"Grenada", id:"Grenada"},
    {name:"Guadeloupe", id:"Guadeloupe"},
    {name:"Guam", id:"Guam"},
    {name:"Guatemala", id:"Guatemala"},
    {name:"Guinea", id:"Guinea"},
    {name:"Guyana", id:"Guyana"},
    {name:"Haiti", id:"Haiti"},
    {name:"Hawaii", id:"Hawaii"},
    {name:"Honduras", id:"Honduras"},
    {name:"Hong Kong", id:"Hong Kong"},
    {name:"Hungary", id:"Hungary"},
    {name:"Iceland", id:"Iceland"},
    {name:"Indonesia", id:"Indonesia"},
    {name:"India", id:"India"},
    {name:"Iran", id:"Iran"},
    {name:"Iraq", id:"Iraq"},
    {name:"Ireland", id:"Ireland"},
    {name:"Isle of Man", id:"Isle of Man"},
    {name:"Israel", id:"Israel"},
    {name:"Italy", id:"Italy"},
    {name:"Jamaica", id:"Jamaica"},
    {name:"Japan", id:"Japan"},
    {name:"Jordan", id:"Jordan"},
    {name:"Kazakhstan", id:"Kazakhstan"},
    {name:"Kenya", id:"Kenya"},
    {name:"Kiribati", id:"Kiribati"},
    {name:"Korea North", id:"Korea North"},
    {name:"Korea Sout", id:"Korea South"},
    {name:"Kuwait", id:"Kuwait"},
    {name:"Kyrgyzstan", id:"Kyrgyzstan"},
    {name:"Laos", id:"Laos"},
    {name:"Latvia", id:"Latvia"},
    {name:"Lebanon", id:"Lebanon"},
    {name:"Lesotho", id:"Lesotho"},
    {name:"Liberia", id:"Liberia"},
    {name:"Libya", id:"Libya"},
    {name:"Liechtenstein", id:"Liechtenstein"},
    {name:"Lithuania", id:"Lithuania"},
    {name:"Luxembourg", id:"Luxembourg"},
    {name:"Macau", id:"Macau"},
    {name:"Macedonia", id:"Macedonia"},
    {name:"Madagascar", id:"Madagascar"},
    {name:"Malaysia", id:"Malaysia"},
    {name:"Malawi", id:"Malawi"},
    {name:"Maldives", id:"Maldives"},
    {name:"Mali", id:"Mali"},
    {name:"Malta", id:"Malta"},
    {name:"Marshall Islands", id:"Marshall Islands"},
    {name:"Martinique", id:"Martinique"},
    {name:"Mauritania", id:"Mauritania"},
    {name:"Mauritius", id:"Mauritius"},
    {name:"Mayotte", id:"Mayotte"},
    {name:"Mexico", id:"Mexico"},
    {name:"Midway Islands", id:"Midway Islands"},
    {name:"Moldova", id:"Moldova"},
    {name:"Monaco", id:"Monaco"},
    {name:"Mongolia", id:"Mongolia"},
    {name:"Montserrat", id:"Montserrat"},
    {name:"Morocco", id:"Morocco"},
    {name:"Mozambique", id:"Mozambique"},
    {name:"Myanmar", id:"Myanmar"},
    {name:"Nambia", id:"Nambia"},
    {name:"Nauru", id:"Nauru"},
    {name:"Nepal", id:"Nepal"},
    {name:"Netherland Antilles", id:"Netherland Antilles"},
    {name:"Netherlands", id:"Netherlands (Holland, Europe)"},
    {name:"Nevis", id:"Nevis"},
    {name:"New Caledonia", id:"New Caledonia"},
    {name:"New Zealand", id:"New Zealand"},
    {name:"Nicaragua", id:"Nicaragua"},
    {name:"Niger", id:"Niger"},
    {name:"Nigeria", id:"Nigeria"},
    {name:"Niue", id:"Niue"},
    {name:"Norfolk Island", id:"Norfolk Island"},
    {name:"Norway", id:"Norway"},
    {name:"Oman", id:"Oman"},
    {name:"Pakistan", id:"Pakistan"},
    {name:"Palau Island", id:"Palau Island"},
    {name:"Palestine", id:"Palestine"},
    {name:"Panama", id:"Panama"},
    {name:"Papua New Guinea", id:"Papua New Guinea"},
    {name:"Paraguay", id:"Paraguay"},
    {name:"Peru", id:"Peru"},
    {name:"Phillipines", id:"Philippines"},
    {name:"Pitcairn Island", id:"Pitcairn Island"},
    {name:"Poland", id:"Poland"},
    {name:"Portugal", id:"Portugal"},
    {name:"Puerto Rico", id:"Puerto Rico"},
    {name:"Qatar", id:"Qatar"},
    {name:"Republic of Montenegro", id:"Republic of Montenegro"},
    {name:"Republic of Serbia", id:"Republic of Serbia"},
    {name:"Reunion", id:"Reunion"},
    {name:"Romania", id:"Romania"},
    {name:"Russia", id:"Russia"},
    {name:"Rwanda", id:"Rwanda"},
    {name:"St Barthelemy", id:"St Barthelemy"},
    {name:"St Eustatius", id:"St Eustatius"},
    {name:"St Helena", id:"St Helena"},
    {name:"St Kitts-Nevis", id:"St Kitts-Nevis"},
    {name:"St Lucia", id:"St Lucia"},
    {name:"St Maarten", id:"St Maarten"},
    {name:"St Pierre & Miquelon", id:"St Pierre & Miquelon"},
    {name:"St Vincent & Grenadines", id:"St Vincent & Grenadines"},
    {name:"Saipan", id:"Saipan"},
    {name:"Samoa", id:"Samoa"},
    {name:"Samoa American", id:"Samoa American"},
    {name:"San Marino", id:"San Marino"},
    {name:"Sao Tome & Principe", id:"Sao Tome & Principe"},
    {name:"Saudi Arabia", id:"Saudi Arabia"},
    {name:"Senegal", id:"Senegal"},
    {name:"Seychelles", id:"Seychelles"},
    {name:"Sierra Leone", id:"Sierra Leone"},
    {name:"Singapore", id:"Singapore"},
    {name:"Slovakia", id:"Slovakia"},
    {name:"Slovenia", id:"Slovenia"},
    {name:"Solomon Islands", id:"Solomon Islands"},
    {name:"Somalia", id:"Somalia"},
    {name:"South Africa", id:"South Africa"},
    {name:"Spain", id:"Spain"},
    {name:"Sri Lanka", id:"Sri Lanka"},
    {name:"Sudan", id:"Sudan"},
    {name:"Suriname", id:"Suriname"},
    {name:"Swaziland", id:"Swaziland"},
    {name:"Sweden", id:"Sweden"},
    {name:"Switzerland", id:"Switzerland"},
    {name:"Syria", id:"Syria"},
    {name:"Tahiti", id:"Tahiti"},
    {name:"Taiwan", id:"Taiwan"},
    {name:"Tajikistan", id:"Tajikistan"},
    {name:"Tanzania", id:"Tanzania"},
    {name:"Thailand", id:"Thailand"},
    {name:"Togo", id:"Togo"},
    {name:"Tokelau", id:"Tokelau"},
    {name:"Tonga", id:"Tonga"},
    {name:"Trinidad & Tobago", id:"Trinidad & Tobago"},
    {name:"Tunisia", id:"Tunisia"},
    {name:"Turkey", id:"Turkey"},
    {name:"Turkmenistan", id:"Turkmenistan"},
    {name:"Turks & Caicos Is", id:"Turks & Caicos Is"},
    {name:"Tuvalu", id:"Tuvalu"},
    {name:"Uganda", id:"Uganda"},
    {name:"United Kingdom", id:"United Kingdom"},
    {name:"Ukraine", id:"Ukraine"},
    {name:"United Arab Erimates", id:"United Arab Emirates"},
    {name:"United States of America", id:"United States of America"},
    {name:"Uraguay", id:"Uruguay"},
    {name:"Uzbekistan", id:"Uzbekistan"},
    {name:"Vanuatu", id:"Vanuatu"},
    {name:"Vatican City State", id:"Vatican City State"},
    {name:"Venezuela", id:"Venezuela"},
    {name:"Vietnam", id:"Vietnam"},
    {name:"Virgin Islands (Brit)", id:"Virgin Islands (Brit)"},
    {name:"Virgin Islands (USA)", id:"Virgin Islands (USA)"},
    {name:"Wake Island", id:"Wake Island"},
    {name:"Wallis & Futana Is", id:"Wallis & Futana Is"},
    {name:"Yemen", id:"Yemen"},
    {name:"Zaire", id:"Zaire"},
    {name:"Zambia", id:"Zambia"},
    {name:"Zimbabwe", id:"Zimbabwe"}
  ]