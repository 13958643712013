import * as yup from 'yup';
import React, { useState } from 'react';
import { Alert, Container, Grid, Typography } from '@mui/material';
import AppContext from '../context/appContext';
import ApplicationPaths from '../utils/paths';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { useFormik } from 'formik';
import Button, { ButtonProps } from '@mui/material/Button';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { firebaseApp } from '..';
import firebase from 'firebase';

interface State {
  password: string;
  showPassword: boolean;
}

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: '#06719b',
  color: '#fff',
  boxShadow: 'unset',
  borderRadius: '22px',
  textTransform: 'unset',
  '&:hover': {
    backgroundColor: '#06719b',
    boxShadow: 'unset',
    color: '#fff',
  },
}));

const CustomTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& label.Mui-focused': {
    color: '#212121',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#06719b',
    },
  },
}));

const CustomFormControl = styled(FormControl)<FormControlProps>(({ theme }) => ({
  '& label.Mui-focused': {
    color: '#212121',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#06719b',
    },
  },
}));

const LoginForm = (): JSX.Element => {
  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false,
  });

  const [error, setError] = useState('');

  const [state, setState] = useState({
    buttonText: 'Sign in',
    loading: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const { updateIsAuthenticated } = useContext(AppContext);

  const validationSchema = yup.object({
    email: yup.string().email('Enter a valid email').required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError('');

      try {
        const auth = firebaseApp.auth();
        auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

        auth.onAuthStateChanged(() => {
          if (auth.currentUser) {
            updateIsAuthenticated(true);
          }
        });
        setState({ buttonText: 'Signing in...', loading: true });
        await auth.signInWithEmailAndPassword(values.email, values.password);
      } catch (error) {
        setError('Error: Authentication failed');
      } finally {
        setState({ buttonText: 'Sign in', loading: false });
      }
    },
  });

  return (
    <Container maxWidth="sm">
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: '25px' }} justifyContent="center">
          <Grid item xs={12}>
            <CustomTextField
              fullWidth
              size="medium"
              id="email"
              label="Email"
              type="email"
              color="info"
              value={formik.values.email}
              onChange={formik.handleChange}
              autoComplete="email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              variant="outlined"
              focused
            />
          </Grid>
          <Grid item xs={12}>
            <CustomFormControl fullWidth focused>
              <InputLabel variant="outlined" htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                autoComplete="password"
                error={formik.touched.password && Boolean(formik.errors.password)}
              />
            </CustomFormControl>
            <Typography sx={{ textAlign: 'right' }}>
              <Link
                to={ApplicationPaths.FORGOT_PASSWORD}
                style={{ color: '#06719b', fontSize: 12.5, textAlign: 'right' }}
              >
                Forgot Password?
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {error && (
              <Alert sx={{ my: 2 }} severity="error">
                {error}
              </Alert>
            )}
          </Grid>
          <Grid item xs={6}>
            <CustomButton fullWidth variant={'contained'} type={'submit'} disabled={state.loading}>
              {state.buttonText}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
      <p style={{ marginTop: 30, textAlign: 'center' }}>
        or{' '}
        <Link to={ApplicationPaths.VOLUNTEER_REGISTER} style={{ color: '#06719b' }}>
          Create an Account
        </Link>
      </p>
    </Container>
  );
};

export default LoginForm;
