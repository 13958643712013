import * as yup from 'yup';
import valid from 'card-validator';
import { format } from 'date-fns';

export const cardNumberValidation = yup
  .string()
  .required('Credit card number is required')
  .test('test-number', 'Credit Card number is invalid', (value) => valid.number(value).isValid);
export const securityDigitValidation = yup
  .string()
  .min(3, 'Minimum 3 characters')
  .max(4, 'Maximum 4 characters')
  .required('CVV is required');

export const cardExpiryValidation = yup
  .string()
  .required('Expiry is required')
  .test('test-expiry', 'Expiry date is invalid', (value) => {
    const check = valid.expirationDate(format(new Date(value || ''), 'MM/yy'));
    let checked = check ? true : false;
    if (check) {
      //Check if same year and month
      const currentYear = new Date().toLocaleString('en-US', { year: '2-digit' });
      const currentMonth = new Date().toLocaleString('en-US', { month: '2-digit' });
      checked = currentYear === check.year && currentMonth === check.month ? false : true;
    }
    return checked;
  });
