import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import PersonalDetails from '../components/VolunteerRegisterForm/PersonalDetails';
import SplitLayout from '../layouts/SplitLayout';
import * as yup from 'yup';
import { useFormik } from 'formik';
import AppContext from '../context/appContext';
import { IonContent, IonPage } from '@ionic/react';
import NavigationTabBar from '../components/NavigationTabBar';
import pdtClient from '../lib/api';
import APIEndpoints from '../utils/endpoints';
import { useHistory } from 'react-router';
import ApplicationPaths from '../utils/paths';
import { isValidPostalCode } from '../utils/form'

export interface IVolunteerEditFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  postal_code: string;
}


const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phone: yup.string().required('Phone is required'),
  address1: yup.string().required('Address is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  postal_code: yup.string().required('Postal Code is required'),
});

const ProfileEditPage = () => {
  const { me, updateMe } = useContext(AppContext);
  const history = useHistory();

  const [state, setState] = useState({
    buttonText: 'Update',
    loading: false,
    error: '',
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      address1: '',
      address2: '',
      city: '',
      country: '',
      state: '',
      postal_code: '',
      charity: '',
    },
    validationSchema: validationSchema,
    validate: (values: IVolunteerEditFormValues) => {
      const errors:any = {};

      // Validate the Postal Code conditionally based on the chosen Country
      if (!isValidPostalCode(values.postal_code, values.country)) {
        errors.postal_code = 'Invalid Postal Code';
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        setState({ error: '', loading: true, buttonText: 'Updating...' });
        const { firstName, lastName, address1, address2, city, country, postal_code, phone } = values;
        const update = {
          first_name: firstName,
          last_name: lastName,
          address1,
          address2,
          phone,
          city,
          state: values.state,
          country,
          postal_code,
        };

        await pdtClient.patch(APIEndpoints.UserEdit.replace(':id', me?.id || ''), update);
        setState({ ...state, loading: false, buttonText: 'Update' });

        if (me) {
          updateMe({ ...me, ...update });
        }

        history.push(ApplicationPaths.PROFILE);
      } catch (error) {
        setState({ loading: false, buttonText: 'Update', error: 'An error occurred, try again' });
      }
    },
  });

  useEffect(() => {
    if (me) {
      const { charity, ...values } = me;
      formik.setValues({ ...formik.values, ...values, firstName: me?.first_name, lastName: me?.last_name });
    }
  }, [me]);

  return (
    <>
      <IonPage>
        <IonContent>
          <SplitLayout
            header={
              <>
                <Container>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4" sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                      Profile Edit
                    </Typography>
                  </Box>
                </Container>
              </>
            }
            component={
              <>
                <Box sx={{ m: 3 }}>
                  <PersonalDetails mode="edit" formik={formik} />
                </Box>
              </>
            }
          >
            <>
              <Grid item xs={12}>
                {state.error && (
                  <Alert sx={{ my: 2 }} severity="error">
                    {state.error}
                  </Alert>
                )}
              </Grid>
              <Button
                sx={{
                  mb: 4,
                  backgroundColor: '#06719b',
                  color: '#fff',
                  boxShadow: 'unset',
                  borderRadius: '22px',
                  textTransform: 'unset',
                  '&:hover': {
                    backgroundColor: '#06719b',
                    boxShadow: 'unset',
                    color: '#fff',
                  },
                }}
                fullWidth
                type="submit"
                variant="contained"
                disabled={state.loading}
                onClick={() => {
                  formik.submitForm();
                }}
              >
                {state.buttonText}
              </Button>
            </>
          </SplitLayout>
        </IonContent>
        <NavigationTabBar />
      </IonPage>
    </>
  );
};

export default ProfileEditPage;
