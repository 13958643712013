import { Grid, TextField, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormikProps } from 'formik';
import { IContributorRegisterFormValues } from '../../pages/ContributorEditPage';
import InputMask from 'react-input-mask';

type Props = {
  formik: FormikProps<IContributorRegisterFormValues>;
  showSubscriberCheckbox: boolean;
};

const EditProfile = ({ formik, showSubscriberCheckbox }: Props): JSX.Element => {
  return (
    <>
      <Typography variant={'h2'} sx={{ fontSize: '24px', textAlign: 'center' }} gutterBottom={true}>
        Edit Subscriber
      </Typography>
      <Grid container spacing={3} alignItems={'center'} marginTop={2}>
        <Grid item xs={12} md={12}>
          <TextField
            variant="standard"
            fullWidth
            label="First Name"
            id="first_name"
            name="first_name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.touched.first_name && formik.errors.first_name}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            variant="standard"
            fullWidth
            label="Last Name"
            id="last_name"
            name="last_name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            variant="standard"
            fullWidth
            label="Address 1"
            id="address1"
            name="address1"
            value={formik.values.address1}
            onChange={formik.handleChange}
            error={formik.touched.address1 && Boolean(formik.errors.address1)}
            helperText={formik.touched.address1 && formik.errors.address1}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            variant="standard"
            fullWidth
            label="Address 2"
            id="address2"
            name="address2"
            value={formik.values.address2}
            onChange={formik.handleChange}
            error={formik.touched.address2 && Boolean(formik.errors.address2)}
            helperText={formik.touched.address2 && formik.errors.address2}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <InputMask
            id="phone"
            name="phone"
            onChange={formik.handleChange}
            mask="999 999 9999"
            value={formik.values.phone}
            disabled={false}
          >
            {() => (
              <TextField
                id="phone"
                variant="standard"
                onChange={formik.handleChange}
                fullWidth
                autoComplete="phone"
                label="Phone Number"
                helperText={formik.errors.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
              />
            )}
          </InputMask>
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            variant="standard"
            fullWidth
            label="City"
            id="city"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            variant="standard"
            fullWidth
            label="State/Province"
            id="state"
            name="state"
            value={formik.values.state}
            onChange={formik.handleChange}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            variant="standard"
            fullWidth
            label="Country"
            id="country"
            name="country"
            value={formik.values.country}
            onChange={formik.handleChange}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ width: '296px' }}>
          <TextField
            variant="standard"
            fullWidth
            label="Postal Code"
            id="postal_code"
            name="postal_code"
            value={formik.values.postal_code}
            onChange={formik.handleChange}
            error={formik.touched.postal_code && Boolean(formik.errors.postal_code)}
            helperText={formik.touched.postal_code && formik.errors.postal_code}
          />
        </Grid>
        {showSubscriberCheckbox && (
          <Grid item xs={12}>
            <FormControlLabel
              label="Active Subscriber"
              control={
                <Checkbox
                  name="activeSubscriber"
                  id="activeSubscriber"
                  checked={formik.values.activeSubscriber}
                  value={formik.values.activeSubscriber}
                  onChange={formik.handleChange}
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EditProfile;
