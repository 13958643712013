import React, { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import EditProfile from '../components/VolunteerDashboard/EditContributorProfile';
import { useSnackbar } from 'notistack';
import pdtClient from '../lib/api';
import APIEndpoints from '../utils/endpoints';
import { formatFormErrors, isValidPostalCode } from '../utils/form';
import ContributorDetailsHeader from '../components/VolunteerDashboard/ContributorDetailsHeader';
import { IonContent, IonPage } from '@ionic/react';
import SplitLayout from '../layouts/SplitLayout';
import NavigationTabBar from '../components/NavigationTabBar';
import { useParams } from 'react-router';

export interface IContributorRegisterFormValues {
  first_name: string;
  last_name: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  postal_code: string;
  activeSubscriber: boolean;
}

const validationSchema = yup.object({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  phone: yup.string().required('Phone is required'),
  address1: yup.string().required('Address is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  postal_code: yup.string().required('Postal Code is required'),
});

const ContributorEditPage = (): JSX.Element => {
  const params = useParams<{ id: string | undefined }>();

  const [info, setInfo] = useState({ name: 'name', status: false });

  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = useState({
    loading: false,
    buttonText: 'Update',
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      country: '',
      state: '',
      postal_code: '',
      activeSubscriber: false,
    },
    validationSchema: validationSchema,
    validate: (values: IContributorRegisterFormValues) => {
      const errors:any = {};

      // Validate the Postal Code conditionally based on the chosen Country
      if (!isValidPostalCode(values.postal_code, values.country)) {
        errors.postal_code = 'Invalid Postal Code';
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        setFormState({ buttonText: 'Updating...', loading: true });
        await pdtClient.put(APIEndpoints.UpdateContributorProfile.replace(':id', params.id || ''), {
          ...values,
          activeSubscriber: values.activeSubscriber.toString(),
          postalCode: values.postal_code,
        });
        enqueueSnackbar('Updated', { variant: 'success', autoHideDuration: 3000 });
      } catch (error: any) {
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          formik.setErrors(formattedErrors);
        }
        if (error.response.data.errorMessage) {
          enqueueSnackbar(`Error: ${error.response.data.errorMessage}`, { variant: 'error', autoHideDuration: 3000 });
        } else {
          enqueueSnackbar(`An error occurred`, { variant: 'error', autoHideDuration: 3000 });
        }
      } finally {
        setFormState({ buttonText: 'Update', loading: false });
      }
    },
  });

  const onClickConfirm = () => {
    formik.submitForm();
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setFormState({ ...formState, loading: true });
        const response = await pdtClient.get(APIEndpoints.GetContributorById.replace(':id', params.id || ''));
        const {
          first_name,
          last_name,
          name,
          phone,
          subscription_active,
          city,
          address1,
          address2,
          country,
          postal_code,
          state,
        } = response.data.data;
        setInfo({
          name,
          status: subscription_active,
        });
        formik.setValues({
          first_name,
          last_name,
          address1,
          address2,
          phone,
          city,
          country,
          state,
          postal_code,
          activeSubscriber: subscription_active,
        });
        setFormState({ ...formState, loading: false });
      } catch (e) {
        enqueueSnackbar(`An error occurred`, { variant: 'error', autoHideDuration: 3000 });
      }
    };
    getData();
  }, []);

  return (
    <>
      <IonPage>
        <IonContent>
          <SplitLayout
            header={<ContributorDetailsHeader name={info.name} active={info.status} />}
            component={
              <Box padding={4}>
                <form onSubmit={formik.handleSubmit}>
                  <EditProfile formik={formik} showSubscriberCheckbox={info.status} />
                </form>
              </Box>
            }
          >
            <>
              <Button
                sx={{
                  mb: 4,
                  backgroundColor: '#06719b',
                  color: '#fff',
                  boxShadow: 'unset',
                  borderRadius: '22px',
                  textTransform: 'unset',
                  '&:hover': {
                    backgroundColor: '#06719b',
                    boxShadow: 'unset',
                    color: '#fff',
                  },
                }}
                fullWidth
                type="submit"
                variant="contained"
                onClick={onClickConfirm}
                disabled={formState.loading}
              >
                {formState.buttonText}
              </Button>
            </>
          </SplitLayout>
        </IonContent>
        <NavigationTabBar />
      </IonPage>
    </>
  );
};

export default ContributorEditPage;
