import { Alert, Box, Grid, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import PDTButton from '../Buttons/PDTButton';
import pdtClient from '../../lib/api';
import APIEndpoints from '../../utils/endpoints';
import { formatFormErrors } from '../../utils/form';
import AppContext from '../../context/appContext';

const validationSchema = yup.object({
  charityName: yup.string().required('Charity name is required'),
  message: yup.string(),
  contact: yup.string(),
  address: yup.string(),
});

const RequestCharityForm = () => {
  const { me, updateMe } = useContext(AppContext);
  const [state, setState] = useState({
    success: false,
    loading: false,
    buttonText: 'Request',
    error: '',
  });
  const formik = useFormik({
    initialValues: {
      charityName: '',
      message: '',
      contact: '',
      address: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setState({
          loading: true,
          error: '',
          success: false,
          buttonText: 'Requesting...',
        });
        await pdtClient.post(APIEndpoints.CharitiesRequest, { ...values });
        setState({
          ...state,
          error: '',
          success: true,
          loading: false,
          buttonText: 'Request',
        });

        if (me) {
          updateMe({ charity_requested: true });
        }

        formik.resetForm();
      } catch (error: any) {
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          formik.setErrors(formattedErrors);
        }
        setState({
          ...state,
          loading: false,
          success: false,
          error: 'Request failed',
          buttonText: 'Request',
        });
      }
    },
  });

  if (me?.charity_requested && formik.submitCount === 0) {
    return (
      <Grid container maxWidth="sm" rowSpacing={1} columnSpacing={0} justifyContent={'center'}>
        <Grid item xs={12} md={12}>
          <Alert severity="info">Thank you for requesting a new charity. A team member from Purpose Driven Travel will be in contact with you shortly.</Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container maxWidth="sm" rowSpacing={1} columnSpacing={0} justifyContent={'center'}>
        <Grid item xs={12} md={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Charity Name"
            id="charityName"
            name="charityName"
            value={formik.values.charityName}
            onChange={formik.handleChange}
            error={formik.touched.charityName && Boolean(formik.errors.charityName)}
            helperText={formik.touched.charityName && formik.errors.charityName}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Charity Contact"
            id="contact"
            name="contact"
            value={formik.values.contact}
            onChange={formik.handleChange}
            error={formik.touched.contact && Boolean(formik.errors.contact)}
            helperText={formik.touched.contact && formik.errors.contact}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Address"
            id="address"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Message"
            id="message"
            name="message"
            multiline={true}
            minRows={5}
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {state.error && (
            <Alert sx={{ my: 2 }} severity="error">
              {state.error}
            </Alert>
          )}
          {state.success && (
            <Alert sx={{ my: 2 }} severity="success">
              Charity request sent
            </Alert>
          )}
        </Grid>
        <Grid item xs={6} md={6} sx={{ textAlign: 'center' }}>
          <PDTButton
            variant="contained"
            fullWidth
            disabled={state.loading}
            onClick={() => {
              formik.submitForm();
            }}
            sx={{ mt: 3 }}
          >
            {state.buttonText}
          </PDTButton>
        </Grid>
      </Grid>
      <Box></Box>
    </>
  );
};

export default RequestCharityForm;
