import { Grid, TextField, FormHelperText } from '@mui/material';
import { FormikProps } from 'formik';
import { IRegisterFormValues } from './VolunteerRegister';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { countryList } from '../../utils/form'

import InputMask from 'react-input-mask';

type Props = {
  formik: FormikProps<IRegisterFormValues>;
  mode?: 'create' | 'edit';
};

const handleCountryChange = (e:any, formik:FormikProps<IRegisterFormValues>) => {
  const selected = e.target.value; // selected name
  // formik.values.country = selected
  formik.setFieldValue('country', selected);
}

const PersonalDetails = ({ formik, mode = 'create' }: Props): JSX.Element => {
  return (
    <Grid container maxWidth="md" spacing={3}>
      <Grid item xs={12} md={6}>
        <TextField
          variant="standard"
          fullWidth
          label="First Name"
          id="firstName"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="standard"
          fullWidth
          label="Last Name"
          id="lastName"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          variant="standard"
          fullWidth
          label="Email"
          id="email"
          name="email"
          type="email"
          disabled={mode === 'edit'}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Grid>
      {mode === 'create' && (
        <Grid item xs={12} md={6}>
          <TextField
            type={'password'}
            variant="standard"
            fullWidth
            label="Password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
      )}

      {mode === 'create' && (
        <Grid item xs={12} md={6}>
          <TextField
            type={'password'}
            variant="standard"
            fullWidth
            label="Confirm Password"
            id="passwordConfirm"
            name="passwordConfirm"
            value={formik.values.passwordConfirm}
            onChange={formik.handleChange}
            error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
            helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
          />
        </Grid>
      )}

      <Grid item xs={12} md={12}>
        <InputMask
          id="phone"
          name="phone"
          onChange={formik.handleChange}
          mask="999 999 9999"
          value={formik.values.phone}
          disabled={false}
        >
          {() => (
            <TextField
              id="phone"
              variant="standard"
              onChange={formik.handleChange}
              fullWidth
              autoComplete="phone"
              label="Phone Number"
              helperText={formik.errors.phone}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
          )}
        </InputMask>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          variant="standard"
          fullWidth
          label="Address 1"
          id="address1"
          name="address1"
          value={formik.values.address1}
          onChange={formik.handleChange}
          error={formik.touched.address1 && Boolean(formik.errors.address1)}
          helperText={formik.touched.address1 && formik.errors.address1}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="standard"
          fullWidth
          label="Address 2"
          id="address2"
          name="address2"
          value={formik.values.address2}
          onChange={formik.handleChange}
          error={formik.touched.address2 && Boolean(formik.errors.address2)}
          helperText={formik.touched.address2 && formik.errors.address2}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="standard"
          fullWidth
          label="City"
          id="city"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="standard"
          fullWidth
          label="State/Province"
          id="state"
          name="state"
          value={formik.values.state}
          onChange={formik.handleChange}
          error={formik.touched.state && Boolean(formik.errors.state)}
          helperText={formik.touched.state && formik.errors.state}
        />
      </Grid>
      <Grid item xs={12} md={6}>
      <FormControl fullWidth={true}>
       <InputLabel id="country-label">Country</InputLabel>
        <Select
          id="country"
          name="country"
          label="Country"
          labelId="country-label"
          value={formik.values.country}
          onChange={(e) => handleCountryChange(e, formik)}
          error={formik.touched.country && Boolean(formik.errors.country)}
          input={<OutlinedInput sx={{height: "50px"}} label="Country" />}
        >
          {countryList.map((country: {name:string, id:string}) => (
            <MenuItem
              key={country.name}
              value={country.name}
            >
              {country.name}
            </MenuItem>
          ))}
        </Select>
          {formik.touched.country && formik.errors.country && <FormHelperText error sx={{marginLeft:'0px'}}>{formik.errors.country}</FormHelperText>} 
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="standard"
          fullWidth
          label="Postal Code"
          id="postal_code"
          name="postal_code"
          value={formik.values.postal_code}
          onChange={formik.handleChange}
          error={formik.touched.postal_code && Boolean(formik.errors.postal_code)}
          helperText={formik.touched.postal_code && formik.errors.postal_code}
        />
      </Grid>
    </Grid>
  );
};

export default PersonalDetails;
